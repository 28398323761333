.contact{
    width: 90vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.contactContain{
    width: 70vw;
    margin-left: 15vw;
}
.locationTitle{
    margin-top: 100px;
    margin-left: 90px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-family: 'Quicksand';
    color: var(--secondColor);
}
.location{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.locationP{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    font-family: 'Josefin';
    text-align: center;
    width: 25vw;
}
.contact-link{
    text-decoration: none;
    margin-left: 10px;
    font-size: 16px;
    font-family: 'Code';
    font-weight: 600;
    letter-spacing: 1px;
    color:#474D53 ;
    width: 40vw;
}
.contacth3{
    margin-top: 30px;
    font-size: 18px;
    font-family: 'Barlow';
    letter-spacing: 1px;
    text-transform: uppercase;

}
.contactDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.contactDiv2{
    display: flex;
    flex-direction: column;   
}
.contactDiv3{
    display: flex;
    flex-direction: column;   
    align-items: center;
}
.map{
    width: 450px;
    height: 300px;
    margin-top: -50px;
}
.contactImg{
    margin-top: -50px;
    width: 300px;
    border-radius: 8px;
}
@media screen and (max-width:950px) {
    .contactContain{
        width: 90vw;
        margin-left: 5vw;
    }
    .location{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .locationTitle{
        margin-top: 50px;
        font-size: 16px;
    }
    .locationDetails{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        margin-top: 20px;
    }
    .locationP{
        font-size: 14px;
        font-family: 'Josefin';
        text-align: center;
        margin-left: 10px;
    }
    .contactDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
       
    }
    .contactImg{
        width: 80vw;
        margin-top: 30px;
    }
    .contact-link{
        text-decoration: none;
        margin-left: 10px;
        font-size: 14px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;
        color:#474D53 ;
    }
    .horaireP{
        text-decoration: none;
        margin-left: 10px;
        font-size: 12px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;
        color:#474D53 ;
    }
    .contactDiv3{
        display: flex;
        flex-direction: column;   
        align-items: center;
    }
    .contacth3{
        margin-top: 30px;
        font-size: 14px;
        font-family: 'Barlow';
        letter-spacing: 1px;
        text-transform: uppercase;
    
    }
    .map{
        width: 50vw;
        height: 300px;
    }
    .contactImg{
        width: 250px;
        border-radius: 8px;
    }
}
@media screen and (max-width:700px) {
    .contact{
        width: 100vw;
    }
    .contactContain{
        width: 95vw;
        margin-left: 2.5vw;
    }
    .location{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .locationTitle{
        margin-top: 50px;
        font-size: 16px;
    }
    .locationDetails{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        margin-top: 20px;
    }
    .locationP{
        font-size: 14px;
        font-family: 'Josefin';
        text-align: center;
        margin-left: 0px;
        width: 80%;
    }
    .contactDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
       
    }
    .contactImg{
        width: 80vw;
        margin-top: 30px;
    }
    .contact-link{
        text-decoration: none;
        margin-left: 10px;
        font-size: 14px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;
        color:#474D53 ;
    }
    .horaireP{
        text-decoration: none;
        margin-left: 10px;
        font-size: 12px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;
        color:#474D53 ;
    }
    .contactDiv3{
        margin-top: 10px;
        display: flex;
        flex-direction: column;   
        align-items: center;
    }
    .contacth3{
        margin-top: 30px;
        font-size: 14px;
        font-family: 'Barlow';
        letter-spacing: 1px;
        text-transform: uppercase;
    
    }
    .map{
        width: 95vw;
        height: 250px;
    }
}