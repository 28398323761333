.navbarCollection{
  align-items: center;
  width: 100vw;
  font-family: 'Tommy';
  font-size: 12px;
  text-transform: uppercase;
}
.navbarCollection>a{
  text-decoration: none;
  color: black;
}
.categoryTitle.redText{
    color: brown;
}
.select{
  border-bottom: 1px solid rgba(0, 0, 0, 0.658);
  line-height: 28px;
}
.subSelect{
    background-color: var(--thirdColor);
    color: #f1f1f1;
    padding: 4px;
    border-radius: 2px;
}
.categoryTitle {
    font-size: 20px;
    font-family: 'Quicksand';
    text-transform: uppercase;
    padding-bottom: 5px;
    text-decoration: none;
    color: black;
  }

  .subfamilyItem {
    display: block; 
    text-transform: uppercase;
    font-size: 14px;
    padding-top: 5px;
    color: var(--secondColor);
    text-decoration: none;
  }
  .subsubfamilyItem{
    display: block; 
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 5px;
    text-decoration: none;
    color: var(--secondColor);
  }
  @media screen and (max-width:1200px) {
    .navbarCollection{
      align-items: center;
      width: 100vw;
      font-family: 'Tommy';
      font-size: 12px;
      text-transform: uppercase;
    }
    .categoryTitle.redText{
        color: brown;
    }
    .subSelect{
        background-color: var(--thirdColor);
        color: #f1f1f1;
        padding: 4px;
        border-radius: 2px;
    }
    .categoryTitle {
        font-size: 14px;
        font-family: 'Quicksand';
        text-transform: uppercase;
        padding-bottom: 5px;
        text-decoration: none;
        color: black;
      }
    
      .subfamilyItem {
        display: block; 
        text-transform: uppercase;
        font-size: 14px;
        padding-top: 5px;
        text-decoration: none;
        color: var(--secondColor);
      }
  }
  @media screen and (max-width:700px) {
    .navbarCollection{
      align-items: center;
      width: 100vw;
      font-family: 'Tommy';
      font-size: 10px;
      text-transform: uppercase;
    }
    .categoryTitle.redText{
        color: brown;
    }
    .subSelect{
        background-color: var(--thirdColor);
        color: #f1f1f1;
        padding: 0px;
        border-radius: 2px;
    }
    .categoryTitle {
      margin-top: -3px;
        font-size: 10px;
        font-family: 'Tommy';
        text-transform: uppercase;
        padding-bottom: 4px;
        text-decoration: none;
        color: black;
      }
    
      .subfamilyItem {
        display: block; 
        text-transform: uppercase;
        font-size: 10px;
        padding-top: 0px;
        text-decoration: none;
        color: var(--secondColor);
      }
      .subsubfamilyItem{
        display: block; 
        text-transform: uppercase;
        font-size: 9px;
        padding-top: 5px;
        text-decoration: none;
        color: var(--secondColor);
      }
  }
  