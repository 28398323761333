.hidden {
    display: none; 
  }

  .promoLabel {
    position: absolute;
    text-transform: uppercase;
    font-family: 'Tommy';
    top: 0px;
    left: 0px;
    background-color: #ff0000;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    z-index: 9900;
  }
  
  .newLabel {
    position: absolute;
    text-transform: uppercase;
    font-family: 'Tommy';
    top: 0px;
    left: 0px;
    background-color: var(--thirdColor);
    color: #fff;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    z-index: 9900;
  }
  .card {
    position: relative;
    margin-bottom: 15px;
  }
  .cardMedia{
    width: 260px;
    height: 260px;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    object-fit: fill;
  }
  
  .cardMediaDialog{
    width: 450px;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    object-fit: cover;
  }
  
  @media screen and (max-width:1200px) {
    .card {
      position: relative;
      margin-bottom: 10px;
    }
  .promoLabel {
    padding: 4px;
    font-size: 10px;
  }
.cardMedia{
  width: 220px;
  height: 220px;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  object-fit: cover;
}


.cardMediaDialog{
  width: 400px;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  object-fit: cover;
}
}  

  @media screen and (max-width:700px) {
    .card {
      position: relative;
      margin-bottom: 10px;
    }
  .promoLabel {
    padding: 4px;
    font-size: 10px;
  }
.cardMedia{
  width: 160px;
  height: 160px;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  object-fit: cover;
}


.cardMediaDialog{
  width: 300px;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  object-fit: cover;
}
}  