.topBtn{
  display: inline-block;
    position: fixed;
    bottom: 20px;
    right: 0px;
    z-index: 99;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    animation: float 2s ease-in-out infinite;
    background-color: rgba(0, 255, 255, 0.116);
  }
  .topBtn.hidden {
    display: none;
  }
  
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  
  @media screen and (max-width:700px) {
    .topBtn{
      right: 1vw;
    }
  }