.footer{
    height: 60vh;
    margin-top: 100px;
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
}
hr{
    width: 80vw;
    border-top: 2px solid var(--thirdColor);
}
.foots{
    display: flex;
    flex-direction: row;
}
.footer-logo{
    width: 200px;
}
.foot{
    width: 40vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.lastFoot{
    width: 40vw;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.foot>h4{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Barlow';
}
.foot>p{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Barlow';
    text-align: center;
    color:#474D53 ;
}
.lastFoot>h5{
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Neto';
}
.privacy{
   font-family: 'Itim';
   color: black;
   font-weight: 600;
}
.footer-link{
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-family: 'Barlow';
    font-weight: 600;
    letter-spacing: 1px;
    color:#474D53 ;

}
@media screen and (max-width:700px) {
    .footer{
        height: auto;
        margin-top: 100px;
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    hr{
        width: 90vw;
        margin-left: -5vw;
        border-top: 1px solid var(--thirdColor);
    }
    .foots{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-logo{
        width: 100px;
    }
    .foot{
        width: 90vw;
        height: auto;
        margin-left: -5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .foot>h4{
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .foot>h5{
        font-size: 13px;
    }
    .lastFoot{
        height: 10vh;
        width: 90vw;
    }
    .lastFoot>h5{
        font-size: 10px;
    }
    .foot>p{
        font-size: 11px;
    }
    .footer-link{
        text-decoration: none;
        margin-top: 10px;
        margin-bottom: 20px;
        color: black;
        font-size: 12px;
        font-family: 'Code';
        font-weight: 600;
        letter-spacing: 1px;

    }
}