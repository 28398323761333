
/* Container du slider */
.slider-container {
  width: 90vw; 
  margin: auto;
}

/* Style des cartes produits dans le slider */
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
}

.custom-slider .slick-dots {
  display: flex !important;
  justify-content: center;
  margin-left: -40px;
}

.custom-slider .slick-dots li {
  margin: 0 5px;
  list-style: none;
  display: inline-block;
}

.custom-slider .slick-dots li button {
  margin-top: 20px;
  font-size: 10px;
  background-color: #ddd;
  border-radius: 0%;
  width: 4px;
  height: 4px;
  cursor: pointer;
}

.custom-slider .slick-dots li.slick-active button {
  background-color: var(--thirdColor); /* Couleur des dots actifs */
  border: 1px solid var(--thirdColor); /* Couleur de la bordure des dots actifs */
}

  @media screen and (max-width:700px) {
    .slider-container {
      width: 90vw; 
      margin: auto;
    }
    
    /* Style des cartes produits dans le slider */
    .product-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #ddd;
      padding: 20px;
      margin: 10px;
      background-color: #fff;
    }
    
    .custom-slider .slick-dots {
      display: flex !important;
      justify-content: center;
      margin-left: 0px;
    }
    
    .custom-slider .slick-dots li {
      margin: 0 5px;
      list-style: none;
      display: inline-block;
    }
    
    .custom-slider .slick-dots li button {
      margin-top: 20px;
      font-size: 10px;
      background-color: #ddd;
      border-radius: 0%;
      width: 2px;
      height: 2px;
      cursor: pointer;
    }
    
    .custom-slider .slick-dots li.slick-active button {
      background-color: var(--thirdColor); /* Couleur des dots actifs */
      border: 1px solid var(--thirdColor); /* Couleur de la bordure des dots actifs */
    }
  }