.allProduct {
  margin-top: 50px;
  margin-bottom: 50px;
  height: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  align-items: center;
  align-content: center;
  align-self: center;
}
.allProduct.singleProduct {
  gap: 0px;
}
.pagination {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: -60px;
  margin-bottom: 60px;
  z-index: 111;
}
@media screen and (max-width: 1100px) {
  .allProduct {
    margin-top: 50px;
    margin-bottom: 20px;
    height: auto;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    align-items: center;
    align-content: center;
    align-self: center;
  }
  .pagination {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -60px;
    margin-bottom: 60px;
    z-index: 111;
  }
}
@media screen and (max-width: 700px) {
  .allProduct {
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
    display: flex;
    align-items: center;
   flex-direction: column;
  }
  .pagination {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -60px;
    margin-bottom: 60px;
    z-index: 111;
  }
}
