.produitCard {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
  text-align: center;
}

.produitCards {
  margin-top: 25px;
  width: 60vw;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
  justify-content: space-evenly;
}
.project-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
  opacity: 1;
  padding: 10px;
  transform: translateY(30%);
  transition: all 0.7s ease-in-out;
}
.project {
  position: relative;
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 0px;
  transition: transform 0.7s ease-in-out;
  box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
}

.project:hover {
  transform: scale(1);
}

.project:hover .project-image {
  transform: translateY(0);
  opacity: 0;
}

.project:hover .project-info {
  transform: translateY(-100%);
  color: black;
  opacity: 1;
}
.project-info2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.507);
  opacity: 0;
  padding: 10px;
  color: black;
  transform: translateY(100%);
  transition: all 0.7s ease-in-out;
}

.project:hover {
  transform: scale(1);
}

.project:hover .project-info2 {
  transform: translateY(0%);
  opacity: 1;
  background-color: var(--thirdColor);
}
.project-image {
  width: 100%;
  height: 250px;
  object-fit: cover; /* Ajout de cette ligne */
  cursor: pointer;
  transition: transform 0.7s;
}
.produitTitle {
  width: 100vw;
  position: absolute;
  bottom: 30px;
  margin-top: 100px;
  font-size: 14px;
  color: var(--thirdColor);
  text-transform: uppercase;
  font-family: "Quicksand";
  letter-spacing: 1px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px;
}
@media screen and (max-width: 1200px) {
  .produitCard {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
  }

  .produitCards {
    margin-top: 25px;
    width: 80vw;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
    justify-content: space-evenly;
  }
  .project-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
    padding: 10px;
    transform: translateY(30%);
    transition: all 0.7s ease-in-out;
  }
  .project {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 0px;
    transition: transform 0.7s ease-in-out;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
  }

  .project:hover {
    transform: scale(1);
  }

  .project:hover .project-image {
    transform: translateY(0);
    opacity: 0;
  }

  .project:hover .project-info {
    transform: translateY(-100%);
    color: black;
    opacity: 1;
  }
  .project-info2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.507);
    opacity: 0;
    padding: 10px;
    color: black;
    transform: translateY(100%);
    transition: all 0.7s ease-in-out;
  }

  .project:hover {
    transform: scale(1);
  }

  .project:hover .project-info2 {
    transform: translateY(0%);
    opacity: 1;
    background-color: var(--thirdColor);
  }
  .project-image {
    width: 100%;
    height: 200px;
  }
  .produitTitle {
    width: 100vw;
    position: absolute;
    bottom: 20px;
    margin-top: 100px;
    font-size: 12px;
    color: var(--thirdColor);
    text-transform: uppercase;
    font-family: "Quicksand";
    letter-spacing: 1px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 4px;
  }
}
@media screen and (max-width: 700px) {
  .produitCard {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
  }

  .produitCards {
    margin-top: 25px;
    width: 90vw;
    display: grid;
    grid-template-columns: auto auto ;
    gap: 20px;
    justify-content: space-evenly;
  }
  .project-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
    padding: 10px;
    transform: translateY(30%);
    transition: all 0.7s ease-in-out;
  }
  .project {
    position: relative;
    width: 160px;
    height: 180px;
    overflow: hidden;
    margin: 0px;
    transition: transform 0.7s ease-in-out;
    box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
  }

  .project:hover {
    transform: scale(1);
  }

  .project:hover .project-image {
    transform: translateY(0);
    opacity: 0;
  }

  .project:hover .project-info {
    transform: translateY(-100%);
    color: black;
    opacity: 1;
  }
  .project-info2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.507);
    opacity: 0;
    padding: 10px;
    color: black;
    transform: translateY(100%);
    transition: all 0.7s ease-in-out;
  }

  .project:hover {
    transform: scale(1);
  }

  .project:hover .project-info2 {
    transform: translateY(0%);
    opacity: 1;
    background-color: var(--thirdColor);
  }
  .project-image {
    width: 100%;
    height: 180px;
  }
  .produitTitle {
    width: 100vw;
    position: absolute;
    bottom: 25px;
    margin-top: 100px;
    font-size: 9px;
    color: var(--thirdColor);
    text-transform: uppercase;
    font-family: "Quicksand";
    letter-spacing: 1px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 4px;
  }
}
