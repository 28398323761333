.cardDetailsImg{
    width: 300px;
    height: auto;
    border-radius: 4px;
    cursor:zoom-in;
}
.cardDetailsImgTech{
    width: 350px;
    height: auto;
    border-radius: 4px;
    cursor:zoom-in;
}
.cardMediaDialogTech{
    width: 70vw;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    object-fit: cover;
  }
  
.productcardDetails{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.morecardDetail{
    margin-left: 50px;
    width: 400px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

}
.morecardDetail>h2{
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Quicksand";
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
.morecardDetail>span{
    margin-top: 30px;
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Barlow";
}
.dimension {
    display: inline-block;
    line-height: 22px;
  }
  
.morecardDetail>p{
   
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 5px;
    font-family: "Poppins";
    color: black;
}
@media screen and (max-width: 1200px) {
    .cardDetailsImg{
        width: 25vw;
        height: auto;
        margin-left: 5px;
    }
    .cardDetailsImgTech{
        width: 28vw;
        height: auto;
        border-radius: 4px;
        cursor:zoom-in;
    }
    .cardMediaDialogTech{
        width: 95vw;
        height: auto;
        transition: transform 0.4s ease-in-out;
        cursor: pointer;
        object-fit: cover;
      }
    .productcardDetails{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .morecardDetail{
        margin-left: 20px;
        width: 35vw;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    
    }
    .morecardDetail>h2{
        width: 100%;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Quicksand";
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetail>span{
        margin-top: 20px;
        width: 90%;
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Barlow";
    }


}
@media screen and (max-width: 700px) {
    .cardDetailsImg{
        width: 30vw;
        height: auto;
        margin-left: 0px;
    }
    .cardDetailsImgTech{
        width: 45vw;
        height: auto;
        border-radius: 4px;
        cursor:zoom-in;
    }
    .productcardDetails{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .morecardDetail{
        margin-left: 0px;
        width: 55vw;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    
    }
    .morecardDetail>h2{
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Quicksand";
        font-weight: 700;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetail>span{
        margin-top:15px;
        width: 95%;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Barlow";
    }


}