.carouselHome{
  height: 500px;
  width: 500px;
}
  .carouselHome img {
    width: 100%;
    height: 450px;
    object-fit: fill;
    border-radius: 4px;
}
.dotsUl{
  margin: 0; /* Set margin to 0 */
  position: relative;
  left: 50%; /* Center horizontally */
  bottom: 80px; /* Adjust the distance from the bottom as needed */
  transform: translateX(-50%); 
}
.slick-dots li button:before {
  color: white !important;
  font-size: 80px  !important;
  content:'.'; 
}

  @media screen and (max-width:950px) {
    .carouselHome{
      height: 250px;
      width: 70vw;
      margin-top: 30px;
    }
      .carouselHome img {
        width: 100%;
        height: 400px;
        object-fit: fill;
        border-radius: 4px;
    }
    .dotsUl{
      margin: 0; /* Set margin to 0 */
      position: absolute;
      left: 50%; /* Center horizontally */
      bottom: 80px; /* Adjust the distance from the bottom as needed */
      transform: translateX(-50%); 
    }
    .slick-dots li button:before {
      color: white !important;
      font-size: 80px  !important;
      content:'.'; 
    }
  }
  @media screen and (max-width:700px) {
    .carouselHome{
      height: auto;
      width: 80vw;
      margin-top: 60px;
    }
      .carouselHome img {
        width: 100%;
        height: 250px;
        object-fit: fill;
        border-radius: 4px;
    }
    .dotsUl{
      margin: 0; 
      position: relative;
      left: -5%; /* Center horizontally */
      bottom: 80px; 
      transform: translateX(-0%); 
    }
    .slick-dots li button:before {
      color: white !important;
      font-size: 80px  !important;
      content:'.'; 
    }
  }