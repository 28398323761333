.header {
  width: 100vw;
  height: 80px;
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  background-color: #ffffffee;
  box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
  z-index: 9999;
  animation: load 1s ease-in-out;
}
/* Add this to your existing CSS */

.dropdown {
  position: relative;
  z-index: 9999;
}

.subDropdown {
  position: absolute;
  width: 200px;
  left: 100%;
  top: 0px;
  text-align: center;
  padding: 2px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}
.subDropdown > Link {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  text-decoration: none;
  color: var(--firstColor);
  align-items: center;
}
.dropdown:hover .subcategories {
  display: block;
}

/* Add other necessary styling for dropdown and subcategories */

.socialLinks {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
}
.socialLink {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  text-decoration: none;
  color: var(--firstColor);
  align-items: center;
  transition: color 0.7s;
}
.socialLink:hover {
  color: var(--thirdColor);
}
@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navMenu {
  display: none;
}
.navLink {
  margin-left: 30px;
  color: var(--firstColor);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Tommy";
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}

.navLink .down {
  padding: 2px;
  font-size: 12px;
}
.navLink .down:hover {
  background-color: var(--thirdColor);
  padding: 2px;
  font-size: 14px;
  border-radius: 4px;
  color: aliceblue;
}
.navLink:hover {
  color: var(--thirdColor);
  transition: all 0.4s linear;
}
.navLink.active {
  color: var(--thirdColor);
  transition: all 0.8s linear;
}

.navbar {
  width: 95vw;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}
.logo {
  width: 150px;
  height: auto;
  margin: 100px;
}
.right-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 999;
  margin-right: 10px;
}
.linkList {
  width: auto;
  margin-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
  height: auto;
  list-style-type: none;
}
.none {
  display: none;
}
.linkMenu {
  position: fixed;
  top: -15px;
  right: 0;
  height: 102vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #333333ee;
  backdrop-filter: blur(2px);
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.linkMenu.show {
  transform: translateX(2%);
}

.linkMenu li {
  list-style: none;
}
.collectionLink {
  margin-top: 5px;
}
.dropdown {
  position: absolute;
  margin-left: -40px;
  text-align: center;
  padding: 6px 10px;
  margin-top: 10px;
  background-color: white;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.dropdown.active {
  opacity: 1;
  visibility: visible;
}

.dropdown li {
  list-style: none;
  margin-left: -65px;
  line-height: 30px;
  color: var(--firstColor);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Tommy";
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .header {
    height: 60px;
  }

  .logo {
    width: 80px;
  }
  .navbar {
    height: 60px;
    font-size: 18px;
  }
  .navLink {
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Josefin";
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
  }
  .navLink.down {
    font-size: 14px;
  }
  .logo {
    width: 80px;
    margin: 30px;
  }
  .separation {
    z-index: 900;
    width: 40vw;
    border-bottom: 1px #b4b4b4 solid;
    margin-top: 0px;
  }

  .linkList {
    display: none;
  }
  .socialLinks {
    width: 60vw;
    text-decoration: none;
    margin-right: 30px;
  }
  .linkMenu {
    position: fixed;
    top: -15px;
    right: -5px;
    height: 104vh;
    width: 70vw;
    text-align: center;
    background-color: var(--secondColor);
    backdrop-filter: blur(2px);
    transform: translateX(120%);
    transition: transform 0.3s ease;
    font-size: 12px;
  }
  #navMenu {
    margin-right: 30px;
    height: 100vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .sousCatLink {
    text-align: center;
    margin-left: -50px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .navbar {
    height: 60px;
    font-size: 14px;
  }
  .navLink {
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Josefin";
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
  }
  .navLink.down {
    font-size: 13px;
  }
  .logo {
    width: 80px;
    margin: 30px;
  }
  .separation {
    z-index: 900;
    width: 40vw;
    border-bottom: 1px #b4b4b4 solid;
    margin-top: 0px;
  }

  .linkList {
    display: none;
  }
  .socialLinks {
    width: 70vw;
    text-decoration: none;
    margin-right: 30px;
  }
  .linkMenu {
    position: fixed;
    top: -15px;
    right: -5px;
    height: 104vh;
    width: 104vw;
    text-align: center;
    background-color: var(--secondColor);
    backdrop-filter: blur(2px);
    transform: translateX(120%);
    transition: transform 0.3s ease;
    font-size: 12px;
  }
  #navMenu {
    margin-right: 30px;
    height: 100vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .sousCatLink {
    text-align: center;
    margin-left: -50px;
    margin-top: 10px;
  }
  .subCategoryLink{
    text-align: left;
  }
}
