.home{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 150px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.homeTop{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90vw;
    margin-left:5vw;

}
.homeImg{
    width: 300px;
    height: 450px;
    border-radius: 4px;
}
.homeImgV{
    width: 43vw;
    height: 250px;
}

.homeTitle{
    margin-top: -20px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
   color: var(--secondColor);
}
.homeTop>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 50%;
}
.homeP{
    text-align: center;
    margin-top: 0px;
    font-size: 14px;
    font-family: 'Quicksand';
    color: var(--firstColor);
    line-height: 24px;
    padding: 10px;
 }
 .homeLogo{
    width: 300px;
    margin-top: -100px;
 }
 .presentation{
    margin-top: 120px;
    width: 70vw;
    margin-left:12.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
}
.presentation>p{
  text-align: center;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Quicksand';
    color: var(--firstColor);
    line-height: 24px;
    padding: 10px;
    width: 60%;
}
.presentation>h2{
    margin-top: -10px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
   padding: 4px 10px ;
   border-radius: 2px;
   text-align: center;
   color: white;
   background-color: var(--thirdColor);
}

.presentationTitle3 {
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
    top: -20px; 
    left: 50%;
    transform: translateX(-50%); 
    background-color: white;
    padding: 0 10px; 
  }
.productIcon{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border: 0.5px solid rgba(0, 0, 0, 0.137);
    padding: 20px; 
}
.productIcon {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border: 0.5px solid rgba(0, 0, 0, 0.137);
    padding: 25px;
  }
  
  .productIcon > div {
    width: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    font-family: 'quicksand';
    text-align: center;
    text-decoration: none;
    transition: transform 0.3s ease-in-out; /* Ajoutez la transition ici */
  }
  
  .productIcon > div:hover {
    transform: translateY(-5px); /* Effet de transition lors du survol */
  }
  
  .productIcon > div > span {
    display: block;
    margin-top: auto; 
  }
  
  /* Aligne les éléments en fonction de la ligne de base du texte */
  .productIcon {
    align-items: baseline;
  } 
.iconImg{
    width: 80px;
    cursor: pointer;
    margin-bottom: 10px;
}
.hidden {
    display: none;
  }
  
  .is-visible {
    display: block;
  }
  .newProduct{
    width: 90vw;
    margin-left: 5vw;
    margin-top: 50px;
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto auto  ;
    place-items: center;
    align-items: center;
  }
  .frnsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 10px;
  }
  .frnsDiv2{
    border: 0.5px solid rgba(0, 0, 0, 0.288);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    height: 200px;
    padding: 20px 10px;
  }
  .frnsDiv2>p{
    text-align: center;
    font-family: 'Quicksand';
    font-size: 14px;
  }

  .frnsImg1{
    width: 500px;
  }
  .frnsImg2{
    width: 250px;
    height: 70px;
  }

 .showroom{
    margin: 20px 0px;
    width:700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }
 .showroomImgContainer {
    width: 350px;
    height: 400px;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
}

.showroomImg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: transform 0.7s;
}

.showroomImg:hover {
    transform: scale(1.2);
}

.showroomDiv{
    width: 320px;
    height: 400px;
    background-color: #232323;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0px 10px;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    color: white;
}
.showroomDiv>h4{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: azure;
    margin-top: -15px;
}
.showroomDiv>span{
    font-size: 14px;
    text-transform:capitalize;
    margin-top: -25px;
}
.showroomDiv>a{
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: var(--thirdColor);
}

@media screen and (max-width:950px) {
    .home{
        width: 100vw;
        height: auto;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 60px;
        animation: load 1s ease-in-out; 
        overflow-x: hidden;
    }
    .homeTop{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 90vw;
        margin-left:5vw;
        margin-bottom: 40px;
    }
    .homeTitle{
        margin-top: -20px;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        line-height: 28px;
    }
    .homeTop>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
        width: 80%;
    }
    .homeP{
        text-align: center;
        margin-top: 0px;
        font-size: 14px;
        line-height: 24px;
        padding: 10px;
     }
     .homeLogo{
        width: 200px;
        margin-top: 0px;
     }
     .presentation{
        margin-top: 50px;
        width: 70vw;
        margin-left:12.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 20px;
    }
    .presentation>h2{
        margin-top: 10px;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
       font-family: 'Quicksand';
       padding: 4px 10px ;
       border-radius: 2px;
       text-align: center;
       color: white;
       background-color: var(--thirdColor);
    }
    .presentation>p{
        font-size: 14px;
        font-weight: 600;
        width: 80%;
    }
    .presentationTitle3 {
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 1px;
       font-family: 'Quicksand';
       font-size: 16px;
        top: -10px; 
        left: 50%;
        transform: translateX(-50%); 
        background-color: white;
        padding: 0 10px; 
      }
    .productIcon{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        border: 0.5px solid rgba(0, 0, 0, 0.137);
        padding: 20px; 
    }
    .productIcon {
        display: flex;
        flex-direction: row;
        width: 110%;
        align-items: center;
        justify-content: space-around;
        border: 0.5px solid rgba(0, 0, 0, 0.137);
        padding: 15px;
      }
      
      .productIcon > div {
        width: 90px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
      }
      
      .productIcon > div:hover {
        transform: translateY(-5px); 
      }
      
      .productIcon > div > span {
        display: block;
        margin-top: auto; 
      }
      
      .productIcon {
        align-items: baseline;
      } 
    .iconImg{
        width: 70px;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .hidden {
        display: none;
      }
      
      .is-visible {
        display: block;
      }
      .newProduct{
        width: 90vw;
        margin-left: 5vw;
        margin-top: 50px;
        display: grid;
        gap: 10px;
        grid-template-columns: auto auto auto auto  ;
        place-items: center;
        align-items: center;
      }
      .frnsDiv{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 120%;
        padding: 20px 10px;
      }
      .frnsDiv2{
        border: 0.5px solid rgba(0, 0, 0, 0.288);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 45%;
        height: 200px;
        padding: 20px 10px;
      }
      .frnsDiv2>p{
        text-align: center;
        font-family: 'Quicksand';
        font-size: 14px;
      }
      .frnsImg2{
        width: 200px;
        height: 70px;
      }
    
     .showroom{
        margin: 20px 0px;
        width:700px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
     }
     .showroomImgContainer {
        width: 350px;
        height: 400px;
        border-radius: 4px;
        overflow: hidden;
        object-fit: cover;
    }
    
    .showroomImg {
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: transform 0.7s;
    }
    
    .showroomImg:hover {
        transform: scale(1.2);
    }
    
    .showroomDiv{
        width: 320px;
        height: 400px;
        background-color: #232323;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding:0px 10px;
        justify-content: space-evenly;
        font-family: 'Quicksand';
        color: white;
    }
    .showroomDiv>h4{
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: azure;
        margin-top: -15px;
    }
    .showroomDiv>span{
        font-size: 14px;
        text-transform:capitalize;
        margin-top: -25px;
    }
    .showroomDiv>a{
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: var(--thirdColor);
    }
}

@media screen and (max-width:700px) {
  .home{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
}
.homeTop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 95vw;
    margin-left:2.5vw;
    margin-bottom: -40px;
}
.homeTitle{
    margin-top: -20px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    line-height: 24px;
}
.homeTop>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 95%;
}
.homeP{
    text-align: center;
    margin-top: -10px;
    font-size: 12px;
    line-height: 24px;
    padding: 10px;
 }
 .homeLogo{
    width: 150px;
    margin-top: 0px;
 }
 .presentation{
    margin-top: 20px;
    width: 70vw;
    margin-left:10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
}
.presentation>h2{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
   padding: 4px 10px ;
   border-radius: 2px;
   text-align: center;
   color: white;
   background-color: var(--thirdColor);
}
.presentation>p{
  font-size: 13px;
  font-weight: 600;
  width: 95%;
}
.presentationTitle3 {
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
   font-size: 12px;
   width: auto;
    top: -5px; 
    left: 15%;
    transform: translateX(5%); 
    background-color: white;
    padding: 0 10px; 
  }
.productIcon{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border: 0.5px solid rgba(0, 0, 0, 0.137);
    padding: 20px; 
}
.productIcon {
  display: grid;
  grid-template-columns: auto auto auto auto;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border: 0.5px solid rgba(0, 0, 0, 0.137);
    padding: 15px;
  }
  
  .productIcon > div {
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  
  .productIcon > div:hover {
    transform: translateY(-5px); 
  }
  
  .productIcon > div > span {
    display: block;
    margin-top: auto; 
  }
  
.iconImg{
    width: 50px;
    cursor: pointer;
    margin-bottom: 0px;
}
.hidden {
    display: none;
  }
  
  .is-visible {
    display: block;
  }
  .newProduct{
    width: 95vw;
    margin-left: 2.5vw;
    margin-top: 20px;
    display: grid;
    gap: 5px;
    grid-template-columns: auto auto auto auto  ;
    place-items: center;
    align-items: center;
  }
  .frnsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 120%;
    padding: 20px 10px;
  }
  .frnsDiv2{
    border: 0.5px solid rgba(0, 0, 0, 0.288);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    padding: 20px 10px;
    margin-top: 5px;
  }
  .frnsDiv2>p{
    text-align: center;
    font-family: 'Quicksand';
    font-size: 14px;
  }
  .frnsImg2{
    width: 170px;
    height: 60px;
  }

 .showroom{
    margin: 20px 0px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
 }
 .showroomImgContainer {
    width: 75vw;
    height: 400px;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
}

.showroomImg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: transform 0.7s;
}

.showroomImg:hover {
    transform: scale(1.2);
}

.showroomDiv{
  margin-bottom: 5px;
    width: 70vw;
    height: 400px;
    background-color: #232323;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0px 10px;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    color: white;
}
.showroomDiv>h4{
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: azure;
    margin-top: -15px;
}
.showroomDiv>span{
    font-size: 12px;
    text-transform:capitalize;
    margin-top: -25px;
}
.showroomDiv>a{
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: var(--thirdColor);
}
}