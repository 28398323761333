.promotions {
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .promotion {
    width: 100%;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  @media screen and (max-width: 950px) {
    .promotions {
      top: 60px;
      overflow-x: hidden;
    }
    .promotion {
      width: 100%;
      height: auto;
      margin-bottom: 15vh;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      animation: load 1s ease-in-out;
      overflow-x: hidden;
    }
  }
  @media screen and (max-width: 700px) {
    .promotions {
      top: 60px;
      overflow-x: hidden;
    }
    .promotion {
      width: 100%;
      height: auto;
      margin-bottom: 15vh;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      animation: load 1s ease-in-out;
      overflow-x: hidden;
    }
  }