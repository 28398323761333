.products {
  width: 100vw;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 80px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.nav {
  margin-top: 20px;
  position: relative;
}
.product {
  width: 100%;
  margin-left: 2.5%;
  height: auto;
  margin-bottom: 15vh;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.productContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

.pathTitle {
  width: auto;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Quicksand";
  padding: 4px 20px;
  border-radius: 2px;
  text-align: center;
  color: white;
  background-color: var(--thirdColor);
}
@media screen and (max-width: 1200px) {
  .products {
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .nav {
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: 100%;
    margin-left: 2.5%;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  
  .pathTitle {
    width: auto;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Quicksand";
    padding: 4px 20px;
    border-radius: 2px;
    text-align: center;
    color: white;
    background-color: var(--thirdColor);
  }
}
@media screen and (max-width: 700px) {

  .nav {
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: auto;
    margin-left: 2.5%;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }
  
  .pathTitle {
    font-size: 12px;
    width: auto;
    letter-spacing: 1px;
   padding: 4px 10px ;
  }
}
