.fournisseur{
  width: 90vw;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  animation: load 1s ease-in-out; 
  overflow-x: hidden;
}
.presentationFrn{
  margin-top: 120px;
  width: 80vw;
  margin-left:7.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
}
.presentationFrn>h2{
  margin-top: -10px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
 font-family: 'Quicksand';
 padding: 4px 10px ;
 border-radius: 2px;
 text-align: center;
 color: white;
 background-color: var(--thirdColor);
}
.frns{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
margin-top: 80px;
}
.frnsImg1{
  width: 400px;
}
.frnsImg11{
  width: 240px;
  margin-left: 50px;
}
.frns>p{
  text-align: center;
  font-family: 'Quicksand';
}
.certificats{
  margin-top: -30px;
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: space-around;
  border: 0.5px solid rgba(0, 0, 0, 0.137);
  padding: 20px; 
}

.certificats > div > span {
  display: block;
  margin-top: auto; 
}
.certifTitle{
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px;
 font-family: 'Quicksand';
 margin-top: 30px; 
 background-color: white;
}
  .qualityImg{
    margin-top: 10px;
    width: 200px;
    border: 2px outset black;
    box-shadow: 1px 1px 2px 1px rgba(87,87,87,0.75);
    cursor: zoom-in;
  }
  .dialog{
    z-index: 9999;
  }
  .qualityImgDialog{
      width: 500px;
      z-index: 9999000;
  }
  .qualityImgModal{
    width: 40vw;
    text-align: center;
    z-index: 99999990000;
  }
 .refDetail{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
 }
 .refTitle{
  font-family: 'Ubuntu';
  text-transform: uppercase;
  font-size: 16px;
 }
 .refdetails{
  display: grid;
  grid-template-columns: auto auto auto ;
  align-items: flex-start;
  gap: 15px;
 }
 .refdiv{
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 .refdiv>h4{
  color: var(--secondColor);
  text-transform: uppercase;
  font-family: 'Ubuntu';
  font-size: 14px;
  margin-bottom: 4px;
 }
 .refdiv>span{
  color: var(--firstColor);
  text-transform: uppercase;
  font-family: 'Barlow';
  font-size: 13px;
 }

  @media screen and (max-width:950px) {
    .fournisseur{
      width: 100vw;
      height: auto;
      position: absolute;
      display: flex;
      flex-direction: column;
      animation: load 1s ease-in-out; 
      overflow-x: hidden;
    }
    .presentationFrn{
      margin-top: 120px;
      width: 85vw;
      margin-left:5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 20px;
  }
  .presentationFrn>h2{
      margin-top: -10px;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
     font-family: 'Quicksand';
     padding: 4px 10px ;
     border-radius: 2px;
     text-align: center;
     color: white;
     background-color: var(--thirdColor);
  }
    .frns{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    margin-top: 80px;
    }
    .frnsImg1{
      width: 300px;
    }
    .frnsImg11{
      width: 200px;
      margin-left: 50px;
    }
    .frns>p{
      text-align: center;
      font-family: 'Quicksand';
    }
    .certificats{
      margin-top: -30px;
      display: flex;
      flex-direction: row;
      width: 60%;
      align-items: center;
      justify-content: space-around;
      border: 0.5px solid rgba(0, 0, 0, 0.137);
      padding: 20px; 
    }
    
    .certificats > div > span {
      display: block;
      margin-top: auto; 
    }
    .certifTitle{
      position: relative;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 5px;
     font-family: 'Quicksand';
     margin-top: 30px; 
     background-color: white;
    }
      .qualityImg{
        margin-top: 10px;
        width: 150px;
        border: 2px outset black;
        box-shadow: 1px 1px 2px 1px rgba(87,87,87,0.75);
        cursor: zoom-in;
      }
      .dialog{
        z-index: 9999;
      }
      .qualityImgDialog{
          width: 500px;
          z-index: 9999000;
      }
      .qualityImgModal{
        width: 40vw;
        text-align: center;
        z-index: 99999990000;
      }
      .refdetails{
        display: grid;
        grid-template-columns: auto auto  ;
   
       }

}
@media screen and (max-width:700px) {
  .fournisseur{
    width: 100vw;
    height: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    animation: load 1s ease-in-out; 
    overflow-x: hidden;
  }
  .presentationFrn{
    margin-top: 120px;
    width: 85vw;
    margin-left:2.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
}
.presentationFrn>h2{
    margin-top: -10px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
   font-family: 'Quicksand';
   padding: 4px 10px ;
   border-radius: 2px;
   text-align: center;
   color: white;
   background-color: var(--thirdColor);
}
  .frns{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-top: 20px;
  }
  .frnsImg1{
    width: 200px;
  }
  .frnsImg11{
    width: 150px;
    margin-left: 40px;
  }
  .frns>p{
    text-align: center;
    font-family: 'Quicksand';
    font-size: 14px;
  }
  .certificats{
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    justify-content: space-around;
    border: 0.5px solid rgba(0, 0, 0, 0.137);
    padding: 20px; 
  }
  
  .certificats > div > span {
    display: block;
    margin-top: auto; 
  }
  .certifTitle{
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px;
   font-family: 'Quicksand';
   margin-top: 30px; 
   background-color: white;
  }
    .qualityImg{
      margin-top: 10px;
      width: 150px;
      border: 2px outset black;
      box-shadow: 1px 1px 2px 1px rgba(87,87,87,0.75);
      cursor: zoom-in;
    }
    .dialog{
      z-index: 9999;
    }
    .qualityImgDialog{
        width: 400px;
        z-index: 9999000;
    }
    .qualityImgModal{
      width: 40vw;
      text-align: center;
      z-index: 99999990000;
    }
    .refdetails{
      display: grid;
      grid-template-columns: auto  ;
 
     }
}